import { useSeoMeta, useHead } from 'unhead';
import { useBrandingStore } from '@/store/branding';
import { ApplicationCardImage, TwitterCardType, ApplicationDescription } from '@/constants';
import { useRequestURL } from 'nuxt/app';
import { supportedLocales } from '@/utils/i18n';
import { defaultLocale } from '@/i18n.config';
import { getCanonicalUrlFromWindowLocation } from '@/utils/canonical';

export interface SEOMetaData {
  title?: string;
  description?: string;
  image?: string;
  type?: 'article';
  robotsTag?: string;
  canonicalLink?: string;
}

export async function setCanonicalLink(url?: string) {
  useHead({
    link: [{ rel: 'canonical', href: url || getCanonicalUrlFromWindowLocation() }],
  });
}
export async function setSEOTags({ title, description, image, type, robotsTag, canonicalLink }: SEOMetaData) {
  const branding = useBrandingStore();
  useSeoMeta({
    // SEO Meta Information
    title: title || `${branding.data?.vendorName} API Developer`,
    description: description || ApplicationDescription,

    // open graph tags
    ogSiteName: `${branding.data?.vendorName} API Developer`,
    ogType: type || 'article',
    ogImage: image || `${useRequestURL().protocol}//${useRequestURL().host}/_nuxt/assets/meta/nexmo-developer-card.png`,
    ogTitle: title || `${branding.data?.vendorName} API Developer`,
    ogDescription: description || ApplicationDescription,
    ogImageWidth: ApplicationCardImage.Width,
    ogImageHeight: ApplicationCardImage.Height,
    ogUrl: useRequestURL().href,
    twitterCard: TwitterCardType,
    ogLocale: defaultLocale,
    ogLocaleAlternate: supportedLocales.filter(obj => obj.locale !== defaultLocale).map(obj => obj.locale),
    robots: robotsTag ?? 'all',
  });
  await setCanonicalLink(canonicalLink);
}
